import {
  MdDashboard,
  MdLock,
  MdOutlinePayment as MdPayment,
  MdFolder as MdFolder,
  MdOutlinePeople as MdPeople,
  MdOutlineWallet as MdOutlineWallet,
  MdTask,
  
} from "react-icons/md";


import EcommerceNewProduct from "views/admin/main/ecommerce/newProduct";
import EcommerceOrderList from "views/admin/main/ecommerce/orderList";

// Auth Imports
import ForgotPasswordCentered from "views/auth/forgotPassword/ForgotPasswordCenter";
import ForgotPasswordDefault from "views/auth/forgotPassword/ForgotPasswordDefault";
import LockCentered from "views/auth/lock/LockCenter";
import LockDefault from "views/auth/lock/LockDefault";
import SignInCentered from "views/auth/signIn/SignInCenter";
import SignInDefault from "views/auth/signIn/SignInDefault";
import SignUpCentered from "views/auth/signUp/SignUpCenter";
import SignUpDefault from "views/auth/signUp/SignUpDefault";
import VerificationCentered from "views/auth/verification/VerificationCenter";
import VerificationDefault from "views/auth/verification/VerificationDefault";
import Archive from "views/admin/main/ecommerce/archive";
import DocumentCards from "views/admin/документооборот/ecommerce/newProduct";
import EmployeeList from "views/admin/сотрудники/ecommerce/orderList";
import BankList from "views/admin/банковскиеСчета/ecommerce/orderList";
import DocumentUpload from "views/admin/документооборот/ecommerce/newDocument/Product";
import FinancialForecast from "views/admin/банковскиеСчета/ecommerce/productPage/FInancialForecast";
import Overview from "views/admin/main/ecommerce/orderDetails";
import JournalOfOps from "views/admin/1C/ecommerce/orderList";
import { layout } from "@chakra-ui/system";
import DocumentDashboard from "views/admin/1C/ecommerce/newProduct";
import BitrixMain from "views/admin/Bitrix/ecommerce/newProduct";
import TableWithDatePicker from "views/admin/документооборот/ecommerce/archive";

const routes = [
  {
    name: "Главное",
    path: "/main",
    icon: <MdDashboard className="text-inherit h-5 w-5" />,
    layout  : "/admin",
    collapse:  false,
    component: <DocumentDashboard />,
  },
  {
    name: "Оплаты",
    path: "/main",
    icon: <MdPayment className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
          {
            name: "Новая заявка",
            layout: "/admin",
            path: "/main/requests/new-request",
            exact: false,
            component: <EcommerceNewProduct />,
          },
          {
            name: "Заявки на оплату",
            layout: "/admin",
            path: "/main/requests/all-requests",
            exact: false,
            component: <EcommerceOrderList />,
          },
          {
            name: "Архив заявок",
            layout: "/admin",
            path: "/main/requests/archive",
            exact: false,
            component: <Archive />,
          },
        ],
      },
  {
    name: "Документооборот",
    path: "/main",
    icon: <MdFolder className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: "Все документы",
        layout: "/admin",
        path: "/main/documentflow/documents",
        exact: false,
        component: <DocumentCards/>,
      },
    ],
  },
  {
    name: "Справочник",
    path: "/main",
    icon: <MdPeople className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: "Все сотрудники", 
        layout: "/admin",
        path: "/main/reference/all-employees",
        exact: false,
        component: <EmployeeList/>,
      },
      
    ],
  },
  {
    name: "Остатки по банкам",
    path: "/main",
    icon: <MdOutlineWallet className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: "Все банки", 
        layout: "/admin",
        path: "/main/banks/all-banks",
        exact: false,
        component: <BankList/>,
      },
      {
        name: "Financial forecast", 
        layout: "/admin",
        path: "/main/banks/financial-forecast",
        exact: false,
        disabled: true,
      },
      
    ],
  },
  // {
  //   name: "Обзор",
  //   path: "/main",
  //   icon: <MdDashboard className="text-inherit h-5 w-5" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Данные по оплатам", 
  //       layout: "/admin",
  //       path: "/main/overview/payment-data",
  //       exact: false,
  //       component: <Overview/>
  //     },
  //     {
  //       name: "1C", 
  //       layout: "/admin",
  //       path: "/main/overview/1C",
  //       exact: false,
  //       component: <JournalOfOps/>
  //     },
      
  //   ],
  // },
  
  // --- Authentication --
    {
      name: "Авторизация",
      path: "/auth",
      icon: <MdLock className="text-inherit h-5 w-5" />,
      collapse: true,
      items: [
        // --- Sign In ---
        {
          name: "Sign In",
          path: "/sign-in",
          collapse: true,
          items: [

            {
              name: "Centered",
              layout: "/auth",
              path: "/sign-in",
              component: <SignInCentered />,
            },
          ],
        },

      ],
    },
];

export default routes;
