import { useState, useEffect } from "react";
import Card from "components/card";
import Table from "./components/Table";
import { useAuth } from "context/AuthContext"; // Ensure this is correctly imported
import axios from "axios";
import FlexiblePieChart from "../orderDetails/components/PieChart";

interface PaymentRequest {
  payment_amount: number; // e.g., 1000.50
  payment_purpose: string; // e.g., "Electricity bill"
  company: number; // e.g., 3
  requester: number; // e.g., 1
  approvers: number[]; // e.g., [2, 5, 8]
  payment_date: string; // e.g., "2024-11-15"
  verification_status: number; // e.g., 1
  comment: string; // Optional, could be an empty string
  is_checked?: boolean;
  approved_by?: number;
  confirmed_by?: number;  
}

interface FullPaymentRequest {
  payment_amount: number; // e.g., 1000.50
  payment_purpose: string; // e.g., "Electricity bill"
  company: number; // e.g., 3
  requester: number; // e.g., 1
  approvers: UserInfo[]; // e.g., [2, 5, 8]
  payment_date: string; // e.g., "2024-11-15"
  verification_status: number; // e.g., 1
  comment: string; // Optional, could be an empty string
  is_checked?: boolean;
  approved_by?: number;
  confirmed_by?: number;  
}

interface VeryFullPaymentRequest {
  id?: number;
  select?: boolean;
  payment_amount: number; // e.g., 1000.50
  payment_purpose: string; // e.g., "Electricity bill"
  company: number; // e.g., 3
  requester: number; // e.g., 1
  approvers: UserInfo[]; // e.g., [2, 5, 8]
  payment_date: string; // e.g., "2024-11-15"
  verification_status: number; // e.g., 1
  comment: string; // Optional, could be an empty string
  is_checked?: boolean;
  approved_by?: UserInfo;
  confirmed_by?: UserInfo;  
  rejected_by?: UserInfo;
  declined_by?: UserInfo;
}

interface UserInfo {
  id: number;
  first_name: string;
  username: string;
  role: "director" | "shareholder" | "accountant" | string;
  // Add any other user properties you might need
}

const OrderList = () => {
  const [orders, setOrders] = useState<VeryFullPaymentRequest[]>([]);
  const { user } = useAuth();
  const [chartData, setChartData] = useState<PaymentRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [directors, setDirectors] = useState<UserInfo[]>([]);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!user) return; // Ensure user is loaded before fetching
      console.log("user", user);
  
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/payment-requests`);
  
        // Filter based on user role and verification status, including approver and requester checks
        let filteredOrders: PaymentRequest[] = [];
        if (user.role === "director") {
          filteredOrders = response.data.filter(
            (order: PaymentRequest) =>
              order.approvers.includes(user.id) && order.verification_status === 1
          );
        } else if (user.role === "shareholder") {
          filteredOrders = response.data.filter(
            (order: PaymentRequest) =>
              order.verification_status === 2 && order.approvers.includes(user.id)
          );
        } else if (user.role === "accountant") {
          filteredOrders = response.data.filter(
            (order: PaymentRequest) =>
              order.requester === user.id && order.verification_status !== 6
          );
        }
        let filteredOrders2: PaymentRequest[] = [];
        filteredOrders2 = response.data.filter(
          (order: PaymentRequest) =>
            user.companies.includes(order.company)
        );

        setChartData(filteredOrders2);
  
        const responseUsers = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users`);

        const users: UserInfo[] = responseUsers.data;

        const fullPaymentRequests: FullPaymentRequest[] = filteredOrders.map((order) => {
          const approvers = order.approvers.map((approverId) => {
            return users.find((user) => user.id === approverId) as UserInfo;
          });

          return {
            ...order,
            approvers,
          };
        });

        const fullPaymentRequestsWithUsers: VeryFullPaymentRequest[] = fullPaymentRequests.map((order) => {
          const approved_by = users.find((user) => user.id === order.approved_by) as UserInfo;
          const confirmed_by = users.find((user) => user.id === order.confirmed_by) as UserInfo;
          return {
            ...order,
            approved_by,
            confirmed_by,
          };
        });
        console.log("fullPaymentRequestsWithUsers", fullPaymentRequestsWithUsers);
        setOrders(fullPaymentRequestsWithUsers);

      } catch (error) {
        console.error("Failed to fetch orders:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();

    
  }, [user]);
  
  const getStatusCounts = (tableData: VeryFullPaymentRequest[]) => {
    return tableData.reduce((acc, row) => {
      acc[row.verification_status] = (acc[row.verification_status] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
  };
  
  const statusCounts = getStatusCounts(orders);
  
  // // Convert to chart-friendly format
  // const chartData = Object.entries(statusCounts).map(([status, count]) => ({
  //   name: status,
  //   value: count,
  // }));
  
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
    <Card extra={"w-full h-full bg-white mt-3"}>
      <Table tableData={orders}/>
    </Card>
    </div>
  );
};

export default OrderList;
