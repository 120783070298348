import InputField from "components/fields/InputField";
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

interface Company {
  id: number;
  name: string;
}

interface PaymentRequest {
  payment_amount: number;
  payment_purpose: string;
  company: number;
  requester: number;
  approvers: any[];
  payment_date: string;
  verification_status: number;
  payment_type: string;
}

const Product = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const [companies, setCompanies] = useState<Company[]>([]);
  const [paymentRequests, setPaymentRequests] = useState<PaymentRequest[]>([]);
  const paymentTypes = [t('cash'), t('non-cash')];

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        if (user?.companies && user.companies.length > 0) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/companies?token=${localStorage.getItem('token')}`,
          );
          
          console.log("COMPANY", response.data);
          const userCompanies = response.data.filter((company: Company) => 
            user.companies.includes(company.id)
          );
          
          setCompanies(userCompanies);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, [user]);

  const addPaymentRequest = () => {
    const firstAvailableCompany = companies[0];

    if (!firstAvailableCompany) {
      alert(t('noCompaniesAvailable'));
      return;
    }

    setPaymentRequests([
      ...paymentRequests,
      {
        payment_amount: 0,
        payment_purpose: "",
        company: firstAvailableCompany.id,
        requester: user?.id || 0,
        approvers: [],
        payment_date: "",
        verification_status: 1,
        payment_type: "",
      },
    ]);
  };

  const handleRemove = (index: number) => {
    const updatedRequests = paymentRequests.filter((_, i) => i !== index);
    setPaymentRequests(updatedRequests);
  };

  const handleChange = (index: number, field: string, value: any) => {
    const updatedRequests = [...paymentRequests];
    (updatedRequests[index] as any)[field] = value;
    setPaymentRequests(updatedRequests);
  };

  const handleCompanyChange = (index: number, companyId: number) => {
    const isCompanyAlreadyUsed = paymentRequests.some(
      (req, idx) => req.company === companyId && idx !== index
    );

    if (isCompanyAlreadyUsed) {
      alert(t('companyAlreadySelected'));
      return;
    }

    handleChange(index, 'company', companyId);
  };

  const handleSubmit = async () => {
    const updatedRequests = await Promise.all(
      paymentRequests.map(async (request) => {
        try {
          const companyResponse = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/companies/${request.company}?token=${localStorage.getItem('token')}`
          );

          const companyData: Company & { people: { id: number; role: string }[] } = companyResponse.data;

          const approvers = companyData.people
            .filter((person) => person.id && (person.role === 'director' || person.role === 'shareholder'))
            .map((person) => person.id);

          return {
            ...request,
            approvers,
          };
        } catch (error) {
          console.error(`Error fetching company data for company ID ${request.company}:`, error);
          return { ...request, approvers: [] };
        }
      })
    );

    const requestBody = { payment_requests: updatedRequests, token: localStorage.getItem('token') };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/payment-requests/bulk-create/`,
        requestBody
      );

      console.log('Response:', response.data);
      navigate('admin/main/requests/all-requests');
      setPaymentRequests([]);
    } catch (error) {
      console.error('Error submitting bulk payment requests:', error);
    }
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        {t('createPaymentRequest')}
      </h4>

      {paymentRequests.map((request, index) => (
        <div key={index} className="mt-6 grid grid-cols-2 gap-3">
          <div className="col-span-2 md:col-span-1">
            <select
              value={request.company}
              onChange={(e) => handleCompanyChange(index, parseInt(e.target.value))}
              className="w-full rounded-xl border px-3 py-3 mb-4"
            >
              <option value="">{t('selectCompany')}</option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>

            <InputField
              extra="mb-4"
              label={t('paymentAmount')}
              placeholder="10 000kzt"
              type="number"
              value={request.payment_amount.toString()}
              onChange={(e: { target: { value: string } }) => handleChange(index, "payment_amount", parseFloat(e.target.value))}
              id={""}
            />
            <InputField
              extra="mb-4"
              label={t('paymentPurpose')}
              placeholder={t('purpose')}
              type="text"
              value={request.payment_purpose}
              onChange={(e: { target: { value: string } }) => handleChange(index, "payment_purpose", e.target.value)}
              id={""}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <InputField
              extra="mb-4"
              label={t('paymentDate')}
              type="date"
              value={request.payment_date}
              onChange={(e: { target: { value: string } }) => handleChange(index, "payment_date", e.target.value)}
              id={""}
              placeholder={t('paymentDateMMDDYYYY')}
            />
            <select
              value={request.payment_type}
              onChange={(e) => handleChange(index, "payment_type", e.target.value)}
              className="w-full rounded-xl border px-3 py-3"
            >
              <option value="">{t('selectPaymentType')}</option>
              {paymentTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="col-span-2 flex justify-end">
            <button
              onClick={() => handleRemove(index)}
              className="rounded-full bg-red-500 p-2 text-white hover:bg-red-600"
              aria-label="Remove"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9l3.536-3.536a1 1 0 011.414 1.414L11.414 10l3.536 3.536a1 1 0 01-1.414 1.414L10 11.414l-3.536 3.536a1 1 0 01-1.414-1.414L8.586 10 5.05 6.464a1 1 0 011.414-1.414L10 8.586z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      ))}

      <div className="mt-4 flex justify-between">
        <button
          onClick={addPaymentRequest}
          className="rounded-xl bg-gray-700 px-4 py-2 text-white"
        >
          {t('addPaymentRequest')}
        </button>
        <button
          onClick={handleSubmit}
          className="rounded-xl bg-brand-900 px-4 py-2 text-white"
        >
          {t('submit')}
        </button>
      </div>
    </div>
  );
};

export default Product;