import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from 'react-i18next';

interface OsvData {
  Организация_Key: string;
  Account: string;
  Currency: string;
  СтруктурноеПодразделение: string;
  ROW1: string;
  ROW2: string;
  СуммаOpeningBalanceCr: number;
  СуммаOpeningBalanceDr: number;
  СуммаTurnoverCr: number;
  СуммаTurnoverDr: number;
  СуммаClosingBalanceCr: number;
  СуммаClosingBalanceDr: number;
}

const companyDict: { [key: string]: string } = {
  'a/ea57/119307': 'ТОО Артас Премиум',
  'a/ea88/95538': 'Артас Есиль',
  'a/ea88/117317': 'ТОО Артас Логистика'
};

const TableWithDatePicker: React.FC = () => {
  const {t} = useTranslation();
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [accountCode, setAccountCode] = useState<string>("");
  const [data, setData] = useState<OsvData[]>([]);
  const [loading, setLoading] = useState(false);
  const [header1, setHeader1] = useState<string>("");
  const [header2, setHeader2] = useState<string>("");
  const [header3, setHeader3] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [companyCode, setCompanyCode] = useState<string>(Object.keys(companyDict)[0]);

  // Reset data when company changes
  useEffect(() => {
    setData([]);
    setHeader1("");
    setHeader2("");
    setHeader3("");
    setError(null);
  }, [companyCode]);

  const fetchData = async () => {
    if (!startDate || !endDate) {
      setError("Please select both start and end dates.");
      return;
    }
    if (!accountCode.trim()) {
      setError("Please enter an account code.");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/osv-data/?start_date=${startDate}&end_date=${endDate}&account_code=${accountCode}&company_code=${companyCode}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      setData(responseData);
      if (responseData.length > 0) {
        setHeader1(responseData[0]['ExtDimension1_Type']?.split('_')[1] || ""); 
        setHeader2(responseData[0]['ExtDimension2_Type']?.split('_')[1] || "");
        setHeader3(responseData[0]['ExtDimension3_Type']?.split('_')[1] || "");
      }

    } catch (err) {
      setError("Failed to fetch data. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCompanyCode(event.target.value);
    // Clear existing data when company changes
    setData([]);
    setError(null);
  };

  const totalRow = useMemo(() => {
    if (data.length === 0) return null;

    return {
      Организация_Key: 'Total',
      Account: '',
      Currency: '',
      СтруктурноеПодразделение: '',
      ROW1: '',
      ROW2: '',
      СуммаOpeningBalanceCr: data.reduce((sum, item) => sum + item.СуммаOpeningBalanceCr, 0),
      СуммаOpeningBalanceDr: data.reduce((sum, item) => sum + item.СуммаOpeningBalanceDr, 0),
      СуммаTurnoverDr: data.reduce((sum, item) => sum + item.СуммаTurnoverDr, 0),
      СуммаTurnoverCr: data.reduce((sum, item) => sum + item.СуммаTurnoverCr, 0),
      СуммаClosingBalanceCr: data.reduce((sum, item) => sum + item.СуммаClosingBalanceCr, 0),
      СуммаClosingBalanceDr: data.reduce((sum, item) => sum + item.СуммаClosingBalanceDr, 0)
    };
  }, [data]);

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-50 min-h-screen">
      <div className="mb-6">
        <label htmlFor="company-select" className="block text-sm font-medium text-gray-700 dark:text-white">
         {t('selectCompany')}
        </label>
        <select
          id="company-select"
          value={companyCode}
          onChange={handleCompanyChange}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          {Object.entries(companyDict).map(([code, name]) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </select>
      </div>
      
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="bg-blue-600 text-white px-6 py-4">
          <h2 className="text-2xl font-bold">{t('osv')} - {companyDict[companyCode]}</h2>
        </div>
        
        <div className="p-6">
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-6">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('account_code')}
              </label>
              <input
                type="text"
                value={accountCode}
                onChange={(e) => setAccountCode(e.target.value)}
                placeholder="Enter account code"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('Start Date')}
              </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('End Date')}
              </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          
          <div className="flex justify-center mb-6">
            <button
              onClick={fetchData}
              className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {t('Apply Filter')}
            </button>
          </div>

          {loading && (
            <div className="text-center text-gray-600 py-4">{t('loading')}</div>
          )}
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              {error}
            </div>
          )}

          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-600">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                <tr>
                  <th className="px-4 py-3">{t('organization')}</th>
                  <th className="px-4 py-3">{t('account')}</th>
                  <th className="px-4 py-3">{t('currency')}</th>
                  <th className="px-4 py-3">{t('structural_unit')}</th>
                  <th className="px-4 py-3">{t(header1)}</th>
                  <th className="px-4 py-3">{t(header2)}</th>
                  <th className="px-4 py-3">{t('opening_balance_credit')}</th>
                  <th className="px-4 py-3">{t('opening_balance_debit')}</th>
                  <th className="px-4 py-3">{t('turnover_credit')}</th>
                  <th className="px-4 py-3">{t('turnover_debit')}</th>
                  <th className="px-4 py-3">{t('closing_balance_credit')}</th>
                  <th className="px-4 py-3">{t('closing_balance_debit')}</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  <>
                    {data.map((item) => (
                      <tr key={item.Организация_Key} className="border-b hover:bg-gray-50">
                        <td className="px-4 py-3">{item.Организация_Key}</td>
                        <td className="px-4 py-3">{item.Account}</td>
                        <td className="px-4 py-3">{item.Currency}</td>
                        <td className="px-4 py-3">{item.СтруктурноеПодразделение}</td>
                        <td className="px-4 py-3">{item.ROW1}</td>
                        <td className="px-4 py-3">{item.ROW2}</td>
                        <td className="px-4 py-3">{item.СуммаOpeningBalanceCr.toFixed(2)}</td>
                        <td className="px-4 py-3">{item.СуммаOpeningBalanceDr.toFixed(2)}</td>
                        <td className="px-4 py-3">{item.СуммаTurnoverCr.toFixed(2)}</td>
                        <td className="px-4 py-3">{item.СуммаTurnoverDr.toFixed(2)}</td>
                        <td className="px-4 py-3">{item.СуммаClosingBalanceCr.toFixed(2)}</td>
                        <td className="px-4 py-3">{item.СуммаClosingBalanceDr.toFixed(2)}</td>
                      </tr>
                    ))}
                    {totalRow && (
                      <tr className="bg-gray-100 font-bold">
                        <td className="px-4 py-3">{totalRow.Организация_Key}</td>
                        <td className="px-4 py-3">{totalRow.Account}</td>
                        <td className="px-4 py-3">{totalRow.Currency}</td>
                        <td className="px-4 py-3">{totalRow.СтруктурноеПодразделение}</td>
                        <td className="px-4 py-3">{totalRow.ROW1}</td>
                        <td className="px-4 py-3">{totalRow.ROW2}</td>
                        <td className="px-4 py-3">{totalRow.СуммаOpeningBalanceCr.toFixed(2)}</td>
                        <td className="px-4 py-3">{totalRow.СуммаOpeningBalanceDr.toFixed(2)}</td>
                        <td className="px-4 py-3">{totalRow.СуммаTurnoverCr.toFixed(2)}</td>
                        <td className="px-4 py-3">{totalRow.СуммаTurnoverDr.toFixed(2)}</td>
                        <td className="px-4 py-3">{totalRow.СуммаClosingBalanceCr.toFixed(2)}</td>
                        <td className="px-4 py-3">{totalRow.СуммаClosingBalanceDr.toFixed(2)}</td>
                      </tr>
                    )}
                  </>
                ) : (
                  <tr>
                    <td colSpan={12} className="text-center py-4 text-gray-500">
                      {t('no_data')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableWithDatePicker;
