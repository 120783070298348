import { useState, useEffect } from "react";
import { useAuth } from "context/AuthContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

interface PaymentRequest {
  payment_amount: number;
  payment_purpose: string;
  company: number;
  requester: number;
  approvers: number[];
  payment_date: string;
  verification_status: number;
  comment: string;
  is_checked?: boolean;
  approved_by?: number;
  confirmed_by?: number;
  company_name?: string;
}

interface UserInfo {
  id: number;
  first_name: string;
  username: string;
  role: "director" | "shareholder" | "accountant" | string;
}

interface Company {
  id: number;
  name: string;
  link: string;
}

interface BitrixStats {
  totalTasks: number;
  openTasks: number;
  placeholderTasks: number;
}

const BitrixMain = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<PaymentRequest[]>([]);
  const [chartData, setChartData] = useState<PaymentRequest[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
  const [bitrixStats, setBitrixStats] = useState<BitrixStats>({
    totalTasks: 0,
    openTasks: 0,
    placeholderTasks: 0,
  });
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/bitrix-accounts`);
        const userCompanies = response.data.filter((company: Company) => user?.companies.includes(company.id));
        console.log(response.data);
        setCompanies(userCompanies);
        if (userCompanies.length > 0) {
          setSelectedCompany(userCompanies[0].id);
        }
      } catch (error) {
        console.error("Failed to fetch companies:", error);
      }
    };

    fetchCompanies();
  }, [user]);

  useEffect(() => {
    if (selectedCompany !== null) {
      const filteredData = orders.filter((order) => order.company === selectedCompany);
      const updatedChartData = filteredData.map((order) => {
        const company = companies.find((company) => company.id === order.company);
        return {
          ...order,
          company_name: company ? company.name : "Unknown",
        };
      });

      // Mock Bitrix stats generation (replace with actual Bitrix API call)
      const mockBitrixStats: BitrixStats = {
        totalTasks: filteredData.length,
        openTasks: Math.floor(filteredData.length * 0.4),
        placeholderTasks: Math.floor(filteredData.length * 0.2),
      };

      setChartData(updatedChartData);
      setBitrixStats(mockBitrixStats);
    }
  }, [selectedCompany, orders, companies]);

  const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompany(Number(event.target.value));
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
    <div className="bg-white shadow-md rounded-lg p-6">
      <div className="flex items-center mb-6">
        <img src="/image.png" alt="Bitrix24" className="w-12 h-12 mr-4" />
        <h2 className="text-2xl font-bold text-gray-800">{t("bitrixIntegration")}</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {t("company")}
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {t("link")}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {companies.map((company) => (
              <tr key={company.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                  {company.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                  <a
                    href={company.link || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    {company.link || t("no_data")}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  
  );
};

export default BitrixMain;

