import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from 'context/AuthContext';
import CardComponent from './CardComponent';
import CardGrid from './DocumentGrid';
import { useTranslation } from 'react-i18next';

interface Category {
  id: number;
  name: string;
  documents: Document[];
}

interface Document {
  id: number;
  company: number;
  [key: string]: any;
}

interface Company {
  id: number;
  name: string;
}

const DocumentCards = () => {
  const {t} = useTranslation();
  const { user } = useAuth();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const [documents, setDocuments] = useState<any[]>([]);

  useEffect(() => {
    if (user && user.companies) {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/companies`)
        .then(response => {
          const userCompanyIds = user.companies;
          const userCompanies = response.data.filter((company: Company) =>
            userCompanyIds.includes(company.id)
          );
          setCompanies(userCompanies);
        })
        .catch(error => {
          console.error('Error fetching companies:', error);
        });
    }
  }, [user]);

  useEffect(() => {
    if (selectedCompany !== null) {
      const filtered = categories.filter(category => {
        return category.documents.filter(doc => doc.company === selectedCompany).length > 0;
      });
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories([]);
    }
  }, [selectedCompany, categories]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/document-categories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedCategoryId !== null) {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/documents`)
        .then(response => {
          const filteredDocuments = response.data.filter((doc: any) =>
            doc.category.includes(selectedCategoryId)
          );
          setDocuments(filteredDocuments);
        })
        .catch(error => {
          console.error('Error fetching documents:', error);
        });
    }
  }, [selectedCategoryId]);

  return (
    <div className="mt-3 w-full">
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />

      <div className="w-full md:w-3/4 mx-auto mt-4 px-4">
        {/* Company Selector */}
        <div className="mb-4">
          <label htmlFor="companySelect" className="block mb-2 text-sm font-medium text-gray-700">
            {t('selectCompany')}
          </label>
          <select
            id="companySelect"
            className="p-2 border rounded-md w-full"
            value={selectedCompany || ''}
            onChange={(e) => setSelectedCompany(parseInt(e.target.value))}
          >
            <option value="">-- {t('selectCompany')} --</option>
            {companies.map(company => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </select>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
  {filteredCategories.map((category) => (
    <div
      key={category.id}
      className="p-4 sm:p-6 lg:p-8 bg-white rounded-lg shadow-lg cursor-pointer hover:bg-gray-100 flex flex-col break-words"
      onClick={() => setSelectedCategoryId(category.id)}
    >
      <div className="flex-grow">
        <h3 className="text-xs sm:text-md lg:text-lg font-bold text-gray-800 mb-2">
          {category.name}
        </h3>
        <p className="text-sm sm:text-md lg:text-lg text-gray-600">
          {t('document')} {category.documents.length}
        </p>
      </div>
    </div>
  ))}
</div>


        {/* Display documents if a category is selected */}
        {selectedCategoryId !== null && (
          <div className="mt-6">
            <h2 className="text-xl font-bold mb-4">{t('selectedCategoryDocuments')}</h2>
            <CardGrid cards={documents.map((doc, index) => (
              <CardComponent key={index} doc={doc} />
            ))} numOfCards={documents.length} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentCards;
