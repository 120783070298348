import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DocumentTable from "../orderList";
import BitrixMain from "views/admin/Bitrix/ecommerce/newProduct";
import TableWithDatePicker from "views/admin/документооборот/ecommerce/archive";

interface Document {
  Number: string;
  Type: string;
  Date: string;
  Comment: string;
  Author: string;
  Contragent: string;
  Organization: string;
}

const DocumentDashboard: React.FC = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [contragentChartData, setContragentChartData] = useState<any[]>([]);
  const [authorChartData, setAuthorChartData] = useState<any[]>([]);
  const [companyCode, setCompanyCode] = useState<string>("a/ea88/95538");

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884D8"];

  const companyOptions = [
    { value: "a/ea88/95538", label: "Артас Есиль" },
    { value: "a/ea88/117317", label: "ТОО Артас Логистика" },
    { value: "a/ea57/119307", label: "ТОО Артас Премиум" },
  ];

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/document-journal-all/?company_code=${companyCode}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        const data = responseData.data.map((element: any) => ({
          Number: element.Number || "N/A",
          Type: element.Type || "N/A",
          Date: element.Date || "N/A",
          Comment: element["Комментарий"] || "N/A",
          Author: element.Author || "N/A",
          Contragent: element.Contragent || "N/A",
          Organization: element.Organization || "N/A",
        }));

        setDocuments(data);
        setFilteredDocuments(data);
        updateChartData(data);
      } catch (err) {
        setError("Failed to fetch documents. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [companyCode]);

  const updateChartData = (docs: Document[]) => {
    const preparePieChartData = (key: keyof Document) => {
      const chartData = docs.reduce((acc: any, doc) => {
        const keyValue = doc[key];
        acc[keyValue] = (acc[keyValue] || 0) + 1;
        return acc;
      }, {});

      return Object.entries(chartData).map(([name, value]) => ({
        name,
        value,
      }));
    };

    setContragentChartData(preparePieChartData("Contragent"));
    setAuthorChartData(preparePieChartData("Author"));
  };

  const filterDocumentsByDate = () => {
    if (startDate && endDate) {
      const filtered = documents.filter((doc) => {
        const docDate = new Date(doc.Date.split("T")[0]);
        return docDate >= startDate && docDate <= endDate;
      });
      setFilteredDocuments(filtered);
      updateChartData(filtered);
    } else {
      setFilteredDocuments(documents);
      updateChartData(documents);
    }
  };

  return (
    <div className="p-2 md:p-6">
      <h1 className="text-2xl font-bold mb-4">{t("Dashboard")}</h1>
      {error && <p className="text-red-500">{error}</p>}
      {loading ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
          <p className="mt-4 text-xl font-semibold text-gray-700">
            {t("loading")}...
          </p>
        </div>
      ) : (
        <>
          {/* Company Code Dropdown */}
          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-2">{t("selectCompany")}</h2>
            <select
              value={companyCode}
              onChange={(e) => setCompanyCode(e.target.value)}
              className="border p-2 rounded w-full md:w-1/2"
            >
              {companyOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {/* Date Filter */}
          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-2">{t("Filter By Date")}</h2>
            <div className="flex flex-wrap items-center gap-4">
              <DatePicker
                selected={startDate}
                onChange={(date: Date | null) => setStartDate(date)}
                placeholderText={t("Start Date")}
                className="border p-2 rounded w-full sm:w-auto"
              />
              <DatePicker
                selected={endDate}
                onChange={(date: Date | null) => setEndDate(date)}
                placeholderText={t("End Date")}
                className="border p-2 rounded w-full sm:w-auto"
              />
              <button
                onClick={filterDocumentsByDate}
                className="bg-blue-500 text-white px-6 py-2 rounded w-full sm:w-auto"
              >
                {t("Apply Filter")}
              </button>
            </div>
          </div>

          {/* Pie Charts */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Pie Chart by Contragents */}
            <div>
              <h2 className="text-xl font-semibold mb-2">{t("By Contragent")}</h2>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie
                    data={contragentChartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label={(entry) => `${entry.name}: ${entry.value}`}
                  >
                    {contragentChartData.map((_, index) => (
                      <Cell
                        key={`contragent-cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>

            {/* Pie Chart by Authors */}
            <div>
              <h2 className="text-xl font-semibold mb-2">{t("By Author")}</h2>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie
                    data={authorChartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label={(entry) => `${entry.name}: ${entry.value}`}
                  >
                    {authorChartData.map((_, index) => (
                      <Cell
                        key={`author-cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          <DocumentTable />
          <TableWithDatePicker />
          <BitrixMain />
        </>
      )}
    </div>
  );
};

export default DocumentDashboard;
