import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "context/AuthContext";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from 'react-i18next';

export interface Bank {
  id: number;
  name: string;
  balance: number;
  logo: string;
  company: number;
}

export interface Company {
  id: number;
  name: string;
}

interface BalanceHistory {
  balance: number;
  date: string;
}

const BankList = () => {
  const { t } = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [selectedHistoryCompany, setSelectedHistoryCompany] = useState<Company | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [sepBanks, setSepBanks] = useState<Bank[]>([]);
  const [loading, setLoading] = useState(false);
  const [editingBank, setEditingBank] = useState<number | null>(null);
  const [editValue, setEditValue] = useState<string>("");
  const [updating, setUpdating] = useState(false);
  const { user } = useAuth();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleDateChange = async (date: Date) => {
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

    setSelectedDate(date);
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/bank-accounts`
      );

      const fetchedBanks1: Bank[] = response.data;
      const updatedBanks = await Promise.all(
        fetchedBanks1.map(async (bank) => {
          try {
            const balanceHistoryResponse = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/api/bank-accounts/${bank.id}/balance-history`
            );
            const balanceHistory: BalanceHistory[] = balanceHistoryResponse.data;
            const balanceEntry = balanceHistory.find(
              (entry) => entry.date === formattedDate
            );
            return { ...bank, balance: balanceEntry?.balance ?? null };
          } catch {
            return { ...bank, balance: null };
          }
        })
      );
      setSepBanks(updatedBanks);
    } catch (err) {
      setError("Failed to fetch banks or balances. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      if (!user) return;
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/companies`);
        const userCompanies = response.data.filter((company: Company) =>
          user.companies.includes(company.id)
        );
        setCompanies(userCompanies);
      } catch (error) {
        console.error("Failed to fetch companies:", error);
      }
    };
  
    fetchCompanies();
  }, [user]);

  useEffect(() => {
    const fetchBanks = async () => {
      if (!selectedCompany) return;
      setLoading(true);

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/bank-accounts`);
        const filteredBanks = response.data.filter((bank: Bank) => bank.company === selectedCompany.id);
        setBanks(filteredBanks);
      } catch (error) {
        console.error("Failed to fetch banks:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBanks();
  }, [selectedCompany]);

  const handleEditClick = (bank: Bank) => {
    setEditingBank(bank.id);
    setEditValue(bank.balance.toString());
  };

  const formatBalance = (balance: number | null): string => {
    if (balance === null) return "N/A";
    return Math.round(balance).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  const handleUpdateBalance = async (bankId: number) => {
    setUpdating(true);
    try {
      const newBalance = parseFloat(editValue);
      if (isNaN(newBalance)) {
        throw new Error("Invalid balance value");
      }
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/bank-accounts/${bankId}/`, 
        { balance: newBalance }
      );

      setBanks(banks.map(bank => 
        bank.id === bankId ? { ...bank, balance: newBalance } : bank
      ));
      setEditingBank(null);
    } catch (error) {
      console.error("Failed to update balance:", error);
      alert("Failed to update balance. Please try again.");
    } finally {
      setUpdating(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingBank(null);
    setEditValue("");
  };

  // Filter banks in the balance history table based on selected company
  const filteredSepBanks = selectedHistoryCompany
    ? sepBanks.filter(bank => bank.company === selectedHistoryCompany.id)
    : sepBanks;

  if (loading) {
    return <div className="text-center text-lg font-semibold">{t('Loading')}</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">{t('allBanks')}</h1>
      
      <div className="flex flex-col lg:flex-row lg:gap-6">
        {/* First Column: Company and Editable Banks */}
        <div className="lg:w-1/2">
          <h2 className="text-xl font-semibold mb-4">{t('selectCompany')}</h2>
          
          {/* Company Dropdown */}
          <div className="mb-6">
            <label htmlFor="company-select" className="block text-sm font-medium text-gray-700">
              {t('company')}
            </label>
            <select
              id="company-select"
              value={selectedCompany?.id || ""}
              onChange={(e) => {
                const companyId = Number(e.target.value);
                const selected = companies.find((company) => company.id === companyId) || null;
                setSelectedCompany(selected);
              }}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="" disabled>
                {t('selectCompany')}
              </option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
          </div>

          {/* Banks Table */}
          <div className="overflow-x-auto">
            {banks.length > 0 ? (
              <table className="min-w-full border-collapse border border-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 border-b">{t('logo')} </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 border-b">{t('name')}</th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 border-b">{t('balance')}</th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 border-b">{t('actions')}</th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {banks.map((bank) => (
                    <tr key={bank.id} className="hover:bg-gray-100">
                      <td className="px-6 py-4 text-sm text-gray-900 border-b">
                        <img src={bank.logo} alt={`${bank.name} logo`} className="h-8 w-8 rounded-full" />
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900 border-b">{bank.name}</td>
                      <td className="px-6 py-4 text-sm text-gray-900 border-b">
                        {editingBank === bank.id ? (
                          <input
                            type="number"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            className="w-32 px-2 py-1 border rounded focus:ring-blue-500 focus:border-blue-500"
                          />
                        ) : (
                          `₸ ${formatBalance(bank.balance)}`
                        )}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900 border-b">
                        {editingBank === bank.id ? (
                          <div className="flex flex-col sm:flex-row gap-2">
                            <button
                              onClick={() => handleUpdateBalance(bank.id)}
                              disabled={updating}
                              className="flex-1 sm:flex-none px-3 py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-50 text-sm lg:text-base"
                            >
                              {updating ? t('saving') : t('save')}
                            </button>
                            <button
                              onClick={handleCancelEdit}
                              disabled={updating}
                              className="flex-1 sm:flex-none px-3 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 disabled:opacity-50 text-sm lg:text-base"
                            >
                              {t('cancel')}
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={() => handleEditClick(bank)}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            {t('edit')}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-center text-gray-500">{t('noBanks')}</p>
            )}
          </div>
        </div>

        {/* Second Column: Calendar and Balance History */}
        <div className="lg:w-1/2 mt-6 lg:mt-0">
          <h2 className="text-xl font-semibold mb-4">{t('selectDateToViewBalances')}</h2>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            className="border rounded-md shadow-md"
          />

          {/* Loading/Error */}
          {loading && <p className="text-blue-500 mt-4">{t('loading')}</p>}
          {error && <p className="text-red-500 mt-4">{error}</p>}

          {/* Balance History Table */}
          {selectedDate && (
            <div className="overflow-x-auto mt-6">
              <h2 className="text-xl font-semibold mb-4">
                {t('bankBalances')} {selectedDate.toLocaleDateString()}
              </h2>

              {/* Company Filter for Balance History */}
              <div className="mb-4">
                <label htmlFor="history-company-select" className="block text-sm font-medium text-gray-700">
                  {t('selectCompany')}
                </label>
                <select
                  id="history-company-select"
                  value={selectedHistoryCompany?.id || ""}
                  onChange={(e) => {
                    const companyId = e.target.value ? Number(e.target.value) : null;
                    const selected = companyId ? companies.find((company) => company.id === companyId) : null;
                    setSelectedHistoryCompany(selected);
                  }}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
                  <option value="">{t('allCompanies')}</option>
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>

              {filteredSepBanks.length > 0 ? (
                <table className="min-w-full border-collapse border border-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 border-b">{t('logo')}</th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 border-b">{t('name')}</th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 border-b">{t('balance')}</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {filteredSepBanks.map((bank) => (
                      <tr key={bank.id} className="hover:bg-gray-100">
                        <td className="px-6 py-4 text-sm text-gray-900 border-b">
                          <img src={bank.logo} alt={`${bank.name} logo`} className="h-8 w-8 rounded-full" />
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-900 border-b">{bank.name}</td>
                        <td className="px-6 py-4 text-sm text-gray-900 border-b">
                          {bank.balance !== null ? `₸ ${formatBalance(bank.balance)}` : t('no_data')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center text-gray-500">{t('noBanksDate')}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BankList;