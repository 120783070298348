import axios from 'axios';

interface Token {
    access: string;
    refresh: string;
}

interface User{
    token: Token;
    username: string;
    role: string;
}
interface LoginResponse {
    user: User;
}

interface isLoggedInResponse {
    is_authenticated: boolean;
    user: User;
}

interface isLoggedInResponse1{
    is_authenticated: boolean;
    user: User;
}

interface ProfileResponse {
    user_id: number;    
    email: string;
    role: string;
    username: string;
}

interface DetailedUserResponse {
    id: number;    
    email: string;
    role: string;
    username: string;
    companies: number[];
    phone_number: string;
    file_url?: string;
    first_name?: string;
    last_name?: string;
}

export const loginUser = async (username: string, password: string): Promise<LoginResponse> => {
    try {
        const response: { data: LoginResponse } = await axios.post<LoginResponse>(`${process.env.REACT_APP_API_BASE_URL}/api/token/`, { username, password }, 
        );
        console.log(response.data)
        localStorage.setItem('token', response.data.user.token.access);
        console.log("token", response.data.user.token.access);
        const { user } = response.data;
        console.log("hello", response.data, user.role);
        return { user };
    } catch (error) {
        throw new Error('Login failed');
    }
};

export const logoutCtx = async (): Promise<number> => {
    try {
        // change to refresh token
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/logout?token=${localStorage.getItem('token')}`,
            {},
            
        );
        console.log("response", response);
        localStorage.removeItem('token');
        return response.status;
    } catch (error) {
        console.error("Logout failed:", error);
        throw new Error('Logout failed');
    }
};

export const getUserProfile = async (): Promise<ProfileResponse> => {
    try {
        const response: { data: ProfileResponse } = await axios.get<ProfileResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/api/profile?token=${localStorage.getItem('token')}`
        );
        return response.data;
    } catch (error) {
        console.error("Failed to fetch user profile:", error);
        return {
            user_id: 0,
            email: '',
            role: '',
            username: '',
        };
    }
};

export const getUserDetails = async (id: number, role: string): Promise<DetailedUserResponse> => {
    try {
        const response: { data: DetailedUserResponse } = await axios.get<DetailedUserResponse>(
            `${process.env.REACT_APP_API_BASE_URL}/api/users/${id}?token=${localStorage.getItem('token')}`
        );
        return response.data;
    } catch (error) {
        console.error("Failed to fetch user profile:", error);
        return {
            id: 0,
            email: '',
            role: '',
            username: '',
            companies: [],
            phone_number: '',
            file_url: '',
            first_name: '',
            last_name: '',
        };
    }
};




export const refreshToken = async () => {
    try {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/token/refresh/`, {}, 
            { withCredentials: true }
        );
    } catch (error) {
        console.error("Token refresh failed", error);
    }
};

export const isLoggedIn = async (): Promise<isLoggedInResponse1> => {
    try {
        const token = localStorage.getItem('token');
        console.log("token", token);
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/is-logged-in?token=${token}`, 
        );
        console.log("is_authenticated", response.data);
        return response.data;
    } catch (error) {
        console.error("Authentication check failed:", error);
        return { is_authenticated: false, user: {
            username: '', role: '',
            token: undefined
        } };
    }
};

