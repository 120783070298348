import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

const DocumentTable = (): JSX.Element => {
  const [documents, setDocuments] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [companyCode, setCompanyCode] = useState<string>("a/ea88/95538");
  const { t } = useTranslation();

  const companyOptions: Array<{ value: string; label: string }> = [
    { value: "a/ea88/95538", label: "Артас Есиль" },
    { value: "a/ea88/117317", label: "ТОО Артас Логистика" },
    { value: "a/ea57/119307", label: "ТОО Артас Премиум" },
  ];

  const fetchDocuments = async (currentCompanyCode: string): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      const url: URL = new URL(
        `${process.env.REACT_APP_API_BASE_URL}/api/document-pagination/`
      );
      url.searchParams.append("page", page.toString());
      url.searchParams.append("page_size", pageSize.toString());
      url.searchParams.append("company_code", currentCompanyCode);

      const response: Response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch documents");
      }

      const data: any = await response.json();
      const fetchedDocuments: any[] = data?.data || [];
      setDocuments(fetchedDocuments);
      setHasMore(fetchedDocuments.length === pageSize);
    } catch (err: any) {
      setError("Failed to fetch documents");
      console.error(err);
      setDocuments([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments(companyCode);
  }, [page, pageSize, companyCode]);

  const tableRows = useMemo(() => {
    return documents.map((doc: any, index: number) => (
      <tr key={index} className="hover:bg-gray-50">
        <td className="border p-2">{doc.Number}</td>
        <td className="border p-2">{doc.Type}</td>
        <td className="border p-2">{new Date(doc.Date).toLocaleDateString()}</td>
        <td className="border p-2">{doc.Comment || "N/A"}</td>
        <td className="border p-2">{doc.Author || "N/A"}</td>
        <td className="border p-2">{doc.Contragent || "N/A"}</td>
        <td className="border p-2">{doc.Organization}</td>
      </tr>
    ));
  }, [documents]);

  if (loading) {
    return <div className="p-4 text-center">{t("loading")}...</div>;
  }

  if (error) {
    return <div className="p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">{t("Journal of Operations")}</h2>

      {/* Company Code Dropdown */}
      <div className="mb-4">
        <label className="block text-gray-700 font-medium mb-2">
          {t("selectCompany")}
        </label>
        <select
          value={companyCode}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const newCompanyCode: string = e.target.value;
            setCompanyCode(newCompanyCode);
            setPage(1);
          }}
          className="px-3 py-2 border rounded w-full"
        >
          {companyOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="flex flex-wrap items-center mb-4 gap-2">
  <button
    onClick={() => setPage((prev: number) => Math.max(1, prev - 1))}
    disabled={page === 1}
    className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50 text-xs sm:text-sm md:text-base"
  >
    {t("previous")}
  </button>
  <span className="w-full sm:w-auto text-center text-xs sm:text-sm md:text-base">
    {t("page")} {page}
  </span>
  <button
    onClick={() => setPage((prev: number) => prev + 1)}
    disabled={!hasMore}
    className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50 text-xs sm:text-sm md:text-base"
  >
    {t("next")}
  </button>
  <select
    value={pageSize}
    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
      setPageSize(Number(e.target.value));
      setPage(1);
    }}
    className="w-full sm:w-auto px-3 py-2 border rounded text-xs sm:text-sm md:text-base"
  >
    {[5, 10, 20, 50].map((size: number) => (
      <option key={size} value={size}>
        {size} {t("page")}
      </option>
    ))}
  </select>
</div>


      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border">
          <thead className="bg-gray-100">
            <tr>
              <th className="border p-2">{t("number")}</th>
              <th className="border p-2">{t("type")}</th>
              <th className="border p-2">{t("date")}</th>
              <th className="border p-2">{t("comment")}</th>
              <th className="border p-2">{t("author")}</th>
              <th className="border p-2">{t("contragent")}</th>
              <th className="border p-2">{t("organization")}</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>

      {documents.length === 0 && !loading && (
        <div className="text-center text-gray-500 mt-4">
          {t("no_data")}
        </div>
      )}
    </div>
  );
};

export default DocumentTable;

